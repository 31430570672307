.broadcast-form-item {
  margin-bottom: 0 !important;
}

.label-file-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  min-height: 100px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
}

.uploadComplete {
  border-color: #385723;
  background-color: #eeffe9;
}

.ant-row .carousel-template-card {
  background-color: #f8fafc;
  border-radius: 15px;
  font-size: 12px;
}

.broadcast-carosal-card .ant-collapse-header {
  background-color: #f4f7f9 !important;
  padding: 7px 16px !important;
}

@primary-color: #F05742;