.custom-table .ant-table-cell {
  border: 1px dashed black !important;
  height: 60px !important;
}

.delivery-details-form-item {
  margin-bottom: 0 !important;
}

.client-responses-view-conversation-info-panel .ant-drawer-body {
  padding: 0px 12px !important;
}

@primary-color: #F05742;