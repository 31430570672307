@import "~antd/dist/antd.less";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-list-item:hover {
  background-color: #fafbfa;
}

.card-rounded-corner {
  border-color: #c5c5c5;
  border-radius: 10px;
}

.dark {
  border-color: #c5c5c5;
}

.center-alignment {
  margin: auto;
  text-align: center;
}

.whatsapp-button {
  display: flex;
  width: 100%;
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  justify-content: center;
  color: #3d8af7;
}

.phone-number-input-box {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}

.phone-number-input-box > input {
  border: 0px;
  outline-width: 0;
  padding: 0px;
}

a {
  text-decoration: underline !important;
}

/* row data */
.ant-table-tbody > tr > td {
  height: 5px;
  padding: 7px;
}

.ant-pagination-total-text {
  position: absolute !important;
  left: 0 !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-notification-notice-btn {
  float: left !important;
  width: 100% !important;
}

.show-more-details-btn .ant-collapse-header {
  padding: 0px 5px !important;
}

@primary-color: #F05742;