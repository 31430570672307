.template-form-item {
  margin-bottom: 0 !important;
}
.template-form-item-textarea {
  margin-bottom: 1 !important;
}

.template-carosal-card-form-item .ant-collapse-header {
  background-color: #f4f7f9 !important;
  padding: 6px 16px !important;
}
.templates-whatsapp-message-preview-container {
  padding: 15px !important;
  background-color: #ffffff !important;
  border-radius: 15px !important;
  font-size: 12px !important;
}

@primary-color: #F05742;