.react-emoji {
  display: flex !important;
  flex-direction: row-reverse !important;
}
.react-emoji-picker--wrapper {
  right: auto !important;
  width: 100% !important;
}
.emoji-mart-scroll {
  padding: 3px !important;
}
.react-input-emoji--input {
  padding: 6px !important;
}
.react-input-emoji--container {
  margin: 0 !important;
  color: #323232 !important;
  font-size: 14px !important;
}
.react-input-emoji--button__show svg {
  fill: #f05742 !important;
}

.ant-form-item-control-input {
  min-height: 0 !important;
}

@primary-color: #F05742;