.import-contacts-mapping-contact-columns-dropdown
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 13px !important;
}
.import-contacts-mapping-column-search-box {
  font-size: 13px !important;
}

.import-contacts-failed-result .ant-result-icon .anticon-close-circle {
  color: red !important;
}

.import-contacts-tags-input {
  font-size: 13px !important;
}

@primary-color: #F05742;