.left-history-arrow,
.right-history-arrow {
  height: 0;
  width: 0;
  position: absolute;
  top: 50%;
  z-index: 1;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.left-history-arrow {
  border-right: 15px solid #f05742;
  top: 0px;
  margin-right: 100px;
}

.right-history-arrow {
  border-left: 15px solid #f05742;
  right: 10px;
  top: 0%;
  margin-left: -200px;
}

.right-history-action-icons {
  font-size: 30px;
  margin-left: -35px;
  margin-top: -5px;
  z-index: 10;
  background-color: transparent;
}

.left-history-action-icons {
  font-size: 30px;
  margin-right: -30px;
  margin-top: -5px;
  z-index: 10;
  background-color: transparent;
}

.history-container {
  animation: movedown 1s linear forwards;
  opacity: 0;
}

@keyframes movedown {
  0% {
    opacity: 1;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.tickets-form-item {
  margin-bottom: 0 !important;
}

@primary-color: #F05742;