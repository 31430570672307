/* CustomProgress.css */
.custom-progress-container .ant-progress-inner {
  height: 15px !important; /* Adjust the height as needed */
  border-radius: 10px !important; /* Adjust the radius as needed */
  width: 90% !important; /* Adjust the width as needed */
}

.custom-progress-container .ant-progress-bg {
  height: 15px !important; /* Match the height to the inner progress bar */
  border-radius: 10px !important;
}

.buy-addon-form-item {
  margin-bottom: 0 !important;
}

@primary-color: #F05742;