/* Hide scrollbar for Chrome, Safari and Opera */
.knowledgebase-doc-preview,
.knowledgebase-test-answer-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.knowledgebase-doc-preview,
.knowledgebase-test-answer-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.kb-file-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  min-height: 100px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
}

.uploadComplete {
  border-color: #385723;
  background-color: #eeffe9;
}

.kb-form-item {
  margin-bottom: 0 !important;
}

@primary-color: #F05742;