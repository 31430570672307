.whatsapp-flows-categories {
  display: block !important;
  margin-right: 0 !important;
}

// Handle scroll inside text area
.flow-json-text-area::-webkit-scrollbar {
  width: 1px;
}

/* Track */
.flow-json-text-area::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
.flow-json-text-area::-webkit-scrollbar-thumb {
  background: transparent !important ;
}

/* Handle on hover */
.flow-json-text-area::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
}

.flow-json-text-area {
  margin-bottom: 0 !important;
}

@primary-color: #F05742;