.menu-logo {
  height: 35px;
  background-size: 100%;
  margin: 15px 0px 0px 10px;
  color: #ffffff;
  width: 140px;
}

.dropdown-btn > .ant-dropdown-trigger {
  border: none;
  background: #f0f2f5;
}

.dropdown-btn button:first-child {
  display: none;
}

@primary-color: #F05742;