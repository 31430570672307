.whatsapp-onboarding-modal .ant-modal-body {
  padding: 0px !important;
}

.whatsapp-onboarding-modal .anticon svg {
  margin-bottom: 12px !important;
  margin-left: 22px !important;
}

.whatsapp-onboarding-modal-header {
  padding: 20px !important;
}

.whatsapp-onboarding-modal-content {
  padding: 20px !important;
}

.whatsapp-onboarding-modal-check-list {
  display: flex !important;
  flex-direction: column !important;
  margin-left: 0px !important;
}

.whatsapp-onboarding-modal-check-list .ant-checkbox-wrapper {
  margin-left: 0px !important;
  margin-top: 7px !important;
}

.useful-links-collapse .ant-collapse-header {
  background-color: #f4f7f9 !important;
  padding: 10px 16px !important;
}

.copy-value {
  display: inline-block !important;
  margin-bottom: 0 !important;
}

@primary-color: #F05742;